import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/UmnyeNedeli"
import { WhatAreTheBenefits } from "../components/WhatAreTheBenefits"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { CardWithMaxBenefits } from "../components/CardWithMaxBenefits"
import { CashbackHalva } from "../components/CashbackHalva"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefits } from "../components/MoreBenefits"
import { Partners } from "../components/PartnersNew"
import { Reviews } from "../components/Reviews"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"

import { getPageData } from "../helpers/getPageData"

import { PageData } from "../interfaces/pageProps"

const title = "Недели умных рассрочек по карте «Халва» — оформите карту онлайн и активируйте акцию"

const description =
  "Покупайте одежду, обувь, ювелирные изделия и путешествия в рассрочку на 36 месяцев по карте «Халва»! Узнайте сроки и подробные условия участия в акции, оформите карту Халва и активируйте «Недели умных рассрочек»"

const subtext = (
  <>
    <p>
      Вы не платите проценты – за вас это делает магазин. Сумма покупки делится на равные платежи –
      вносите небольшими частями каждый месяц. Получите увеличенный срок рассрочки с акцией «Умные
      недели»! С 25 сентября по 22 октября срок рассрочки увеличивается до 24 месяцев на покупки у
      партнеров акции.
    </p>
  </>
)

const whatsAreTheBenefitsTitle = "10 месяцев рассрочки"

const whatsAreTheBenefitsTetx =
  "Специальные предложения позволяют оформить рассрочку на 10 месяцев без переплат"

const whatsAreTheBenefitsPrice = "10 747 ₽ / мес."

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  return (
    <Layout title={title} description={description} noHeader noFooter noIndex={noIndex}>
      <NewHeader />
      <Banner orderNum="2" />
      <CardWithMaxBenefits orderNum="3" />
      <FormPKW dataLayerName="shortPersonalForm" orderNum="4" />
      <HowInstallmentWorks orderNum="5" />
      <NewCalculator titleLocationBottom subtext={subtext} orderNum="6" />
      <WhatAreTheBenefits
        title={whatsAreTheBenefitsTitle}
        text={whatsAreTheBenefitsTetx}
        price={whatsAreTheBenefitsPrice}
        orderNum="7"
      />
      <CashbackHalva orderNum="8" />
      <MoreBenefits orderNum="9" />
      <Partners orderNum="10" />
      <Reviews orderNum="11" />
      <Questions orderNum="12" />
      <NewFooter ligal={ligal} orderNum="13" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/nedeli-umnyh-rassrochek/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
